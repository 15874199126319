<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">{{ translations.tcAddEditANote }}</h1>
    </page-header>
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h4>
          {{ location.org_name }}
        </h4>
        <h1 class="sm mb-4">
          <span v-if="!note.nte_key">{{ translations.tcAddEditANote }}</span>
          <span v-if="note.nte_key">{{ translations.tcAddEditANote }}</span>
        </h1>
      </section>
      <section class="section-1 bg-block mb-4">
        <div class="body">
          <div>
            <b-form>
              <div class="row">
                <div class="col-8">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-3">
                      <b-form-datepicker
                        id="noteDate"
                        v-model="note.nte_contact_date"
                        class="mb-2 contact_date"
                        :locale="prefCulture"
                        :label-help="translations.tcCalHelp"
                        :label-no-date-selected="translations.tcDatePickerPlaceholder"
                        :placeholder="translations.tcDatePickerPlaceholder"
                        :show-decade-nav="showDecadeNav"
                        :hide-header="hideHeader"
                      >
                        <template #button-content style="padding: 12px">
                          <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                        </template>
                      </b-form-datepicker>
                    </div>
                    <div class="g-row mb-0 mb-sm-3">
                      <div class="g-col-1 d-flex mb-3 mb-sm-0">
                        <b-form-select
                          v-model="note.nte_contact_by_ind_key"
                          :options="[...searchedContact]"
                          class="form-control col-left-input g-select flex-0 mr-3"
                        >
                          <template slot="first">
                            <option :value="null" disabled>{{ translations.tcContactedBy }}</option>
                          </template>
                        </b-form-select>
                        <b-button
                          :name="`contactFindButton`"
                          variant="primary"
                          class="flex-1 btn-pl-xsm"
                          @click="showModal($event, 'personSearchModal')"
                          >{{ translations.tcFind }}
                        </b-button>
                      </div>
                    </div>
                    <div class="g-row mb-0 mb-sm-3">
                      <div class="g-col-1 d-flex mb-3 mb-sm-0">
                        <b-form-select
                          v-model="note.nte_ctt_key"
                          :options="noteTypesTranslated"
                          class="form-control col-left-input g-select flex-0 mr-3"
                        >
                        </b-form-select>
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </section>
      <section class="section-4 bg-block mb-4">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcNote }}</h2>
        </header>
        <div class="body">
          <textarea name="other" v-model="note.nte_note" class="w-100"></textarea>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
              translations.tcSave
            }}</b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">{{
              translations.tcCancel
            }}</b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch
      @selectionMade="applyFoundPerson($event)"
      :camp_country="camp_country"
      :modal_title="modalTitle"
    ></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'card_facility_edit_note',
  mixins: [translationMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      findButton: '',
      searchedContact: [],
      statusKey: null,
      modalTitle: 'Contacted By Search',
      contactOption: [],
      note: {
        nte_entity_key: null,
        nte_contact_by_ind_key: null,
        nte_subject: null,
        nte_note: null,
        nte_contact_date: null,
        nte_ctt_key: null,
        nte_nag_key: null,
        nte_add_user: null,
        nte_delete_flag: 0,
        nte_key: null,
      },
      noteTypesTranslated: [],
      data: {},
    }
  },
  methods: {
    ...mapActions({
      getNoteTypes: 'card/getNoteTypes',
      loadLocationVisitOptions: 'scriptureDistribution/loadLocationVisitOptions',
      saveProspectNote: 'membership/saveProspectNote',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedNote: 'user/setSelectedNote',
      upsertNote: 'card/upsertNote',
    }),
    async handleCancelClick() {
      await Promise.all([await this.setSelectedNote({})]).then(() => {
        this.$router.push({ name: 'card_notes' })
      })
    },
    async handleSaveClick() {
      if (
        !this.note.nte_entity_key ||
        !this.note.nte_contact_by_ind_key ||
        !this.note.nte_note ||
        !this.note.nte_contact_date ||
        !this.note.nte_ctt_key
      ) {
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcAllFieldsRequiredMsg, //'All of the data fields are required. Please complete them before attempting to save.',
        }).then((result) => {
          return
        })
      } else {
        this.note.nte_add_user = this.userId
        // Subject is hard coded to match the old version of theConnection
        this.note.nte_subject = 'Church Contact Notes'
        let result = true
        await Promise.all([this.setLoadingStatus(true), (result = await this.saveProspectNote(this.note))]).then(() => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result ? 'success' : 'error',
            confirmButtonText: this.translations.tcOk,
            text: result
              ? this.translations.tcTheNoteWasSaved
              : `${this.translations.tcSystemErrorMsg}. ${this.translations.tcTheNoteWasNotSaved}`,
          }).then((result) => {
            if (result.value) {
              this.handleCancelClick()
            }
          })
        })
      }
    },
    async applyFoundPerson(evt) {
      this.searchedContact.push({ value: evt.ind_key, text: evt.name_text })
      this.note.nte_contact_by_ind_key = evt.ind_key
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
  },
  async created() {
    if (!this.userSelectedFacilityKey || !this.userSelectedNote) {
      this.$router.push({ name: 'gideon-card-facilities' })
    }
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations('buttons', 'modal-error-messages'),
        this.getComponentTranslations('common.placement-facility-note-types'),
        this.getNoteTypes(),
        this.loadLocationVisitOptions({
          camp_key: this.location.camp_org_key,
        }),
      ]).then((results) => {
        this.stripReadableText(results[1], 'text')

        this.modalTitle = this.translations.tcContactedBySearch
        this.searchedContact = [...this.convertedLocationVisitFormOptions.members]
        this.note = {
          nte_entity_key: this.userSelectedFacilityKey,
          nte_contact_by_ind_key: !!this.userSelectedNote.nte_contact_by_ind_key
            ? this.userSelectedNote.nte_contact_by_ind_key
            : null,
          nte_note: !!this.userSelectedNote.nte_note ? this.userSelectedNote.nte_note : '',
          nte_contact_date: !!this.userSelectedNote.contact_date
            ? new Date(this.userSelectedNote.contact_date)
            : new Date(),
          nte_ctt_key: !!this.userSelectedNote.nte_ctt_key ? this.userSelectedNote.nte_ctt_key : null,
          nte_add_user: this.userIndKey,
          nte_delete_flag: false,
          nte_key: !!this.userSelectedNote.nte_key ? this.userSelectedNote.nte_key : '',
        }
        if (!!this.userSelectedNote.nte_contact_by_ind_key) {
          this.searchedContact.push({
            value: this.userSelectedNote.nte_contact_by_ind_key,
            text: this.userSelectedNote.name,
          })
        }

        this.translateDropdownOptions([
          {
            node: 'placement-facility-note-types',
            store: 'noteTypes',
            disabledPlaceholder: this.translations.tcSelectAType || 'Select a Type',
          },
        ])
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      convertedLocationVisitFormOptions: 'scriptureDistribution/convertedLocationVisitFormOptions',
      location: 'card/getFacilitySummary',
      noteTypes: 'card/getNoteTypes',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
      userSelectedNote: 'user/userSelectedNote',
    }),
    optionsNoteTypes() {
      let types = this.noteTypes.map((type) => {
        return { value: type.value, text: type.display_text }
      })
      return [{ value: null, text: this.translations.tcSelectAType, disabled: true }, ...types]
    },
    camp_country() {
      return this.convertedLocationVisitFormOptions.campCountry.value
    },
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    PersonSearch: PersonSearch,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  width: 45%;
  @include breakpoint(sm) {
    width: 100%;
  }
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
